export default class {
	constructor() {
		this.init();
		this.textHero();
	}

	init() {
		const texts = document.querySelectorAll('.js-text-anm');
		texts.forEach(function(el) {
				let text = el.innerText;
				let textH = el.clientHeight;
				let newSpan = document.createElement('span');
				newSpan.className = 'js-text-anm__in';
				newSpan.innerText = text;

				el.innerHTML = '';
				el.appendChild(newSpan);
				el.style.height = `${textH}px`
		});
	}

	textHero() {
		const copyWrap = document.querySelectorAll('.js-hero-copy');
		const copy = document.querySelectorAll('.js-hero-copy__in');
		copy.forEach(function(el) {
			let text = el.innerText;
			let textH = el.clientHeight;
			let newSpan = document.createElement('span');
			newSpan.className = 'js-hero-copy__in-row';
			newSpan.innerText = text;

			el.innerHTML = '';
			el.appendChild(newSpan);
			el.style.height = `${textH}px`
		});
	}
}
