import Swiper from'swiper/bundle';
import 'swiper/css/bundle';

export default class {
	constructor() {
		this.init();
	}

	init() {
		window.addEventListener('load', () => {
			swiper01();
		});

		//swiper
		const swiper01 = () => {
			let swiperImg;
			let slideItems = document.querySelectorAll('.js-hero__slide');

			swiperImg = new Swiper('.js-hero', {
				// initialSlide: 0,
					speed: 400,
					loop: true,
					allowTouchMove: false,
					autoplay: {
							delay: 4000,
							disableOnInteraction: false,
					},
					pagination: {
							el: ".js-hero-pager",
							type: "fraction",
							renderBullet: (index, className) => `<span class="${className}">${index + 1}</span>`,
					},
			});
		};

	}
}
