import {gsap} from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default class {
    constructor() {
        this.init();
        this.heroTxt();
    }

	init() {
		const fadeItems = gsap.utils.toArray('.js-inview');
		if (fadeItems) {
			fadeItems.forEach((fadeItem) => {
				ScrollTrigger.create({
					//markers: true,
					trigger: fadeItem, //アニメーションが始まるトリガーとなる要素
					start: 'top 75%',
					end: 'bottom 75%',
					toggleClass: {
						targets: fadeItem,
						className: "is-active"
					},
					once: true
				});
			});
		}
	}

	heroTxt() {
		const heroText = document.querySelectorAll('.js-hero-text');
		if (heroText) {
			window.addEventListener('load',()=>{
				heroText.forEach(el => {
					setTimeout(() => {
						el.classList.add('is-active');
					}, 500);
				});
			});
		}
	}
}