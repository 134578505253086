export default class {
	constructor() {
		this.header = '.js-header';
		this.hero = '.js-hero';
		this.init();
	}

	init() {
		const header = document.querySelector(this.header);
		const hero = document.querySelector(this.hero);
		if (hero) {
			let headerH = header.clientHeight;
			let heroH = hero.clientHeight;
			window.addEventListener('scroll', ()=>{
				let scrollH = window.scrollY;
				if (scrollH > heroH) {
					header.classList.add('is-active');
				} else {
					header.classList.remove('is-active');
					if (scrollH > headerH) {
						header.classList.add('is-hidden');
					} else {
						header.classList.remove('is-hidden');
					}
				}
			});
		}
	}
}