export default class {
	constructor() {
			this.subItem = ".js-drawer__sub";
			this.subTrigger = ".js-drawer__sub-trigger";
			this.subContents = ".js-drawer__sub-contents";

			this.init();
	}

	init() {
			const subItems = document.querySelectorAll(this.subItem);

			subItems.forEach(subItem => {
					const subTrigger = subItem.querySelector(this.subTrigger);
					const subContent = subItem.querySelector(this.subContents);

					subTrigger.addEventListener('click', () => {
							const isActive = subTrigger.classList.contains('is-active');

							// クリックされた要素のみを操作する
							subItems.forEach(item => {
									item.classList.remove('is-active');
									item.querySelector(this.subTrigger).classList.remove('is-active');
									item.querySelector(this.subContents).classList.remove('is-active');
							});

							if (!isActive) {
									// クリックした要素にis-activeを付与
									subTrigger.classList.add('is-active');
									subItem.classList.add('is-active');
									subContent.classList.add('is-active');
							}
					});

			});
	}
}
