import {Lottie} from 'lottie-web';
import {gsap} from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default class {
    constructor() {
        this.lottieImg = '.js-lottie';
        this.init();
    }

    init() {
        const img = document.querySelectorAll(this.lottieImg);
				if (img) {
					img.forEach(function(el,index) {
                let elJsonPath = el.dataset.icon;
                let animation = bodymovin.loadAnimation({
                    container: el,
                    renderer: 'svg',
                    loop: false,
                    autoplay: false,
                    path: elJsonPath,
                });
                ScrollTrigger.create({
                    trigger: el,
                    start: 'top 75%',
										end: 'bottom 75%',
                    onEnter: () => animation.play(),
                });
            });
        }
    }
}