import {gsap} from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default class {
	constructor() {
		this.container = document.querySelector('.js-feature-container');
		this.inner = document.querySelector('.js-feature-inner');
		this.contents = document.querySelector('.js-feature-contents');
		this.topFeature();
	}

	topFeature() {
		if (this.container) {
			// パネルのZインデックスを設定
			gsap.set(".js-feature-img", {
				zIndex: (i, target, targets) => targets.length - i
			});
			// パネルのアニメーション
			var images = gsap.utils.toArray('.js-feature-img:not(.-last)');
			images.forEach((image, i) => {
				var tl = gsap.timeline({
					scrollTrigger: {
						trigger: this.container,
						start: () => "top -" + (window.innerHeight * (i + 0.25)),
						end: () => "+=" + window.innerHeight,
						toggleActions: "play none reverse none",
						invalidateOnRefresh: true,
						onEnter: () => {
							this.container.classList.remove(`is-active${i}`);
							this.container.classList.add(`is-active${i + 1}`);
						},
						onLeaveBack: () => {
							this.container.classList.add(`is-active${i}`);
							this.container.classList.remove(`is-active${i + 1}`);
						}
					}
				});
				//tl.to(image, { height: 0 });
			});
			// スクロールトリガーの設定
			ScrollTrigger.create({
				trigger: this.container,
				scrub: true,
				pin: true,
				start: () => "top top",
				end: () => "+=" + ((images.length + 1) * window.innerHeight),
				invalidateOnRefresh: true,
				onLeave: () => {
					this.container.classList.add('is-finished');
				},
				onEnterBack: () => {
					this.container.classList.remove('is-finished');
				},
			});
		}
	}
}
